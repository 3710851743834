import React from 'react'

export default class Story extends React.Component {
    render() {
        return (
            <div className="container story">
                <div className="row">
                    <div className="col-md-offset-2 col-md-8 detail"></div>
                </div>
                <div className="row">
                    <div className="col-offset-12 photos">
                        <div className="photo photo1"></div>
                        <div className="photo photo2"></div>
                        <div className="photo photo3"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-offset-12 photos">
                        <div className="photo photo4"></div>
                        <div className="photo photo5"></div>
                        <div className="photo photo6"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-offset-12 photos">
                        <div className="photo photo7"></div>
                        <div className="photo photo8"></div>
                        <div className="photo photo9"></div>
                    </div>
                </div>
            </div>
        )
    }
}