import React from 'react'
import ReactDOM from 'react-dom'

export default class Registry extends React.Component {

    render() {
        return (
            <div className="container registry">
                <div className="row description">
                    <div className="col-md-offset-3 col-md-6">
                        <p>We are already blessed with much love, laughter and half a household of furniture! Accordingly we have assembled a registry for the items we'd find most useful. Please be aware that gifts are not necessary. Your presence is all that is required.</p>
                        <ul>
                            <li> <a href="http://bit.ly/1MxZ4rD" target="rylisha" className="img-fulfill">
                                Anthropologie
                        </a></li>
                            <li>
                                <a href="http://bit.ly/1OCX0EZ" target="rylisha" className="img-fulfill">
                                    Zola
                                </a>
                            </li>
                            <li>
                                <a href="http://bit.ly/1jPvZRx" target="rylisha" className="img-fulfill">
                                    Pottary Barn
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}