import React from 'react'
import ReactDOM from 'react-dom'
import LazyLoad from 'react-lazyload';
import Photos from '../rylisha-photo.json'
import Preload from "image-preload";
const urls = Photos.map((p) => p.url)
Preload(urls, {
    onComplete: () => {
        console.log('done')
    }
});
export default class Game extends React.Component {
    getPhotos() {
        return Photos.map((photo) => {
            const url = { backgroundImage: "url('" + photo.url + "')" }
            return (
                <div className="pic-wrapper" key={photo._id}>
                    <div className="moment">
                        <div className="filler" style={url}></div>
                        <a href={photo.url} target="_pic">
                            <LazyLoad height={200} offset={100}>
                                <img className="pic" src={photo.url} />
                            </LazyLoad>
                        </a>
                        <br />
                        <div className="name">{photo.name}</div>
                    </div>
                </div>)
        })
    }
    componentDidMount() {

    }
    render() {
        const photo = Photos[0]
        return (
            <div className="container timeline">
                {this.getPhotos()}
            </div >
        )
    }
}