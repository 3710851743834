import React from 'react'
import ReactDOM from 'react-dom'

export default class RSVP extends React.Component {
    render() {
        return (
            <div className="container rsvp messages">
                <div className="row">
                    <div className="col-md-offset-3 col-md-6 thank-you">
                        <p>Thank you for your RSVP, we look forward to celebrating with you</p>
                    </div>
                </div>
            </div>
        )
    }
}