import React from 'react'
import ReactDOM from 'react-dom'
import YouTube from 'react-youtube'


export default class Extra extends React.Component {

    render() {
        const opts = {
            height: '290',
            width: '290',
        };
        return (
            <div className="container extra">
                <div className="row">
                    <div className="col-xs-12 message">
                        <p>Each and every invitation is handcrafted, printed individually at our small but cozy apartment in NYC.</p>
                    </div>
                </div>
                <div className="photos">
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/a1.JPG") + "')" }} className="photo photo4"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/a2.JPG") + "')" }} className="photo photo4"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/a3.JPG") + "')" }} className="photo photo4"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/a4.JPG") + "')" }} className="photo photo4"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/a5.JPG") + "')" }} className="photo photo3"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/b1.JPG") + "')" }} className="photo photo3"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/b2.JPG") + "')" }} className="photo photo3"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/c1.JPG") + "')" }} className="photo photo3"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/c2.JPG") + "')" }} className="photo photo3"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/c3.JPG") + "')" }} className="photo photo3"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/d1.JPG") + "')" }} className="photo photo4"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/d2.JPG") + "')" }} className="photo photo4"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/d5.JPG") + "')" }} className="photo photo4"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/d3.JPG") + "')" }} className="photo photo4"></div>
                    <div className="photo photo2">
                        <YouTube
                            videoId="9C_DDZKa2BA"
                            opts={opts}
                        />
                    </div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/e1.JPG") + "')" }} className="photo photo2"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/d4.JPG") + "')" }} className="photo photo3"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/h1.JPG") + "')" }} className="photo photo3"></div>
                    <div style={{ backgroundImage: "url('" + require("../../public/images/invitation/h2.JPG") + "')" }} className="photo photo3"></div>
                </div>
            </div>
        )
    }
}