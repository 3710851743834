// import a CSS module
// import classes from './main.css';
import React from 'react'
import ReactDOM from 'react-dom'
import i18n from 'simplest-i18n'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Home from './components/Home'
import Story from './components/Story'
import RSVP from './components/RSVP'
import Registry from './components/Registry'
import Extra from './components/Extra'
import Game from './components/Game'

class Layout extends React.Component {
  getNavi() {
    return (
      <div className="row">
        <div className="col-xs-2 col-md-offset-3 col-md-1 item"><Link to="/">Wedding</Link></div>
        <div className="col-xs-2 col-md-1 item"><Link to="/story">Story</Link></div>
        <div className="col-xs-2 col-md-1 item"> <Link to="/rsvp">RSVP</Link></div>
        <div className="col-xs-2 col-md-1 item"> <Link to="/registry">Registry</Link></div>
        <div className="col-xs-2 col-md-1 item"> <Link to="/extra">Extra</Link></div>
        <div className="col-xs-2 col-md-1 item"> <Link to="/game">Game</Link></div>
      </div>)
  }
  render() {
    return (
      <Router>
        <div>
          <div className="container visible-md visible-lg navi">
            {this.getNavi()}
          </div>
          <div className="separator img-fulfill">
            <img data-src="https://rylisha.com/images/devider.png" />
          </div>

          <Route exact path="/" component={Home} />
          <Route exact path="/story" component={Story} />
          <Route exact path="/rsvp" component={RSVP} />
          <Route exact path="/registry" component={Registry} />
          <Route exact path="/extra" component={Extra} />
          <Route exact path="/game" component={Game} />

          <div className="separator img-fulfill flip">
            <img data-src="https://rylisha.com/images/devider.png" />
          </div>
          <div className="container visible-xs visible-sm navi">
            {this.getNavi()}
          </div>
        </div>
      </Router>
    )
  }
}
export default () => {
  const layoutRoot = document.getElementById('root')
  ReactDOM.render(<Layout />, layoutRoot)
};

