import '../style/main.sass'
import './module/jquery-init.js'
import './module/jquery.interactive_bg.min.js'
import Parallax from 'parallax-js'
import Preload from "image-preload";
import main from './main';

const imgs = ["https://rylisha.com/images/branchLeft.png", "https://rylisha.com/images/branchRight.png", "https://rylisha.com/images/logo.png", "https://rylisha.com/images/smFlowerBg.png", "https://rylisha.com/images/midFlowerBg.png", "https://rylisha.com/images/bigFlowerBg.png", "https://rylisha.com/images/devider.png"];
Preload(imgs, {
  onComplete: () => {
    $("img[data-src]").each(function () {
      const $ele = $(this)
      $ele.attr({ src: $ele.data("src") })
    })
    $('#splash, .splash-logo').addClass("done").delay(4000).queue((next) => {
      $('#splash, #logo-splash').remove()
      $(".logo").addClass("done")
      next()
    })
  }
});
const scene = document.getElementById('scene');
new Parallax(scene);
main();
