import React from 'react'
import ReactDOM from 'react-dom'

export default class Home extends React.Component {
    render() {
        return (
            <div>
                <div className="container-fluid messages">
                    <div className="col-md-offset-3 col-md-6 message-top text-center">
                        It's real!
                        <br className="visible-xs visible-sm" />
                        <span className="padding-left-1">We are getting married</span>
                    </div>
                </div>
                <div className="rylisha img-fulfill">
                    <img src={require("../../public/images/elishaRyan.png")} alt="" />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-offset-3 col-md-6 text-centere">
                            <span className="countdown">Saturday 01. 02. 2016 11 AM in the morning</span>
                            <br />
                            <div className="location">
                                <a href="http://www.grandvictoria.com.tw/" target="rylisha">Grand Victoria Hotel</a>
                                <br className="visible-xs visible-sm" />
                                <span className="padding-left-1">Taipei, Taiwan</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}